import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import UeFunds from "../containers/UeFunds/UeFunds";

const UeFundsPage = ({ pageContext }) => (
	<Layout pageContext={pageContext}>
		<UeFunds />
	</Layout>
);
UeFundsPage.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
};

export default UeFundsPage;
