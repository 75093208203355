import React, { useEffect } from "react";
import useTranslations from "../../hoc/useTranslations";
import { scrollToTop } from "../../helpers/helpers";
import Description from "../../components/Description/Description";
import Heading from "../../components/Heading/Heading";
import SEO from "../../components/seo";
import { PAGE } from "../../helpers/constants";
import "./UeFunds.scss";

export const UeFunds = () => {
	const { funds } = useTranslations();

	useEffect(() => {
		scrollToTop();
	}, []);
	return (
		<>
			<SEO page={PAGE.FUNDS} />
			<section className="section funds__section">
				{funds.map(({ title, descriptions }, index) => (
					<article key={index} className="funds__section__article">
						<Heading className="funds__section__heading" type={2}>
							{title}
						</Heading>
						{descriptions.map(description => (
							<Description key={description}>{description}</Description>
						))}
					</article>
				))}
			</section>
		</>
	);
};

export default UeFunds;
